<template>
  <Layout>
    <v-container style="max-width: 991px">
      <v-card class="elevation-0">
        <v-card-title>
          <h2>{{ $t_("AVISO_VOUCHER") }}</h2>
        </v-card-title>
        <v-card-text>
          <p>{{ $t_("MSN_VOUCHER") }}</p>

          <v-row
            align="center"
            justify="center"
            style="max-width: 400px; margin: 0px auto"
          >
            <v-col cols="12">
              <label required>{{ $t_("LOCALIZADOR") }}</label>
              <v-text-field
                v-mask="'XXXXXXXXXXXXXXXX'"
                @input="localizador = localizador.toUpperCase()"
                v-model="localizador"
                type="tel"
                dense
                outlined
                hide-details
                class="mt-3"
                tabindex="1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="text-left d-flex align-center justify-center">
              <div>
                <vue-recaptcha
                  tabindex="10"
                  theme="red"
                  :size="$config.dados.recaptcha"
                  @verify="$root.onCaptchaVerified"
                  @expired="$root.onCaptchaExpired"
                  :language="$axios.getLocal('lang').data"
                  v-if="
                    !$usuario.showModalLogin &&
                    $config.conf &&
                    $config.conf.site_key_recaptcha &&
                    !$sac.recaptchaAtivo
                  "
                  class="vue-captcha"
                  :sitekey="$config.conf.site_key_recaptcha"
                  :loadRecaptchaScript="true"
                ></vue-recaptcha>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex align-center justify-center text-center">
              <v-btn
                x-large
                tabindex="11"
                class="mr-md-3"
                :loading="$usuario.cadastro.loading"
                :color="$config.dados.corprimaria"
                @click="getVaucher()"
                block
                elevation="0"
                style="color: white"
              >
                <strong>{{ $t_("proximo") }}</strong>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </Layout>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { mask } from "vue-the-mask";
export default {
  name: "Voucher",
  components: {
    Layout: () => import("@/layout/Default.vue"),
    VueRecaptcha,
  },
  directives: { mask },
  data() {
    return {
      localizador: "",
    };
  },
  methods: {
    getLocalizador() {
      if (this.$route.query.loc) {
        this.localizador = this.$route.query.loc;
      }
    },
    async getVaucher() {
      if (!this.$axios.getLocal("session")) {
        this.$usuario.showModalLogin = true;
        return;
      }

      let recaptcha = await window.grecaptcha.getResponse();

      if (this.localizador.length != 16) {
        this.$config.alertWarn(this.$t_("ALERTA_VOUCHE"));
        return;
      }

      if (recaptcha == "") {
        window.grecaptcha.reset();
        this.$config.alertWarn(this.$t_("recaptcha_invalido"));
        return;
      }

      this.$superingresso.trocarVoucher(this.localizador, recaptcha).then((response) => {
        // FALTA TRATAR A RESPOSTA
      });
    },
  },
  mounted() {
    this.getLocalizador();
    const time = setInterval(() => {
      if (window.grecaptcha) {
        clearInterval(time);
        window.grecaptcha.execute();
      }
    }, 1000);
  },
};
</script>
